import React, { useState } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import 'bootstrap/dist/css/bootstrap.css';
import Us_1 from './images/us_1.jpeg';
import Us_2 from './images/us_2.jpeg';
import Us_3 from './images/us_3.jpeg';
import Us_4 from './images/us_4.jpeg';
import Us_5 from './images/us_5.jpeg';
import Us_6 from './images/us_6.jpeg';
import Us_7 from './images/us_7.jpeg';
import Us_8 from './images/us_8.jpeg';
import Us_9 from './images/us_9.jpeg';
import Boat from './images/boat.png';
import Music from './sounds/O Poder Dessa Garota (Remix).mp3';
import './App.css';

function App() {
  const [started, setStarted] = useState(false); // Estado para controlar se a música começou
  const [loading, setLoading] = useState(false); // Estado para controlar o spinner de loading
  const [fadeOut, setFadeOut] = useState(false); // Estado para a animação de saída
  const audio = new Audio(Music);

  const handleStart = () => {
    setLoading(true); // Mostra o spinner de loading// Inicia a animação de fade out
    audio.volume = 0; // Começa com volume zero
    audio.play()
      .then(() => {
        setTimeout(() => {
          audio.volume = 0.5; // Aumenta o volume após 2 segundos
        }, 2000);
      })
      .catch(error => {
        console.log("Reprodução automática falhou: ", error);
      });

    setTimeout(() => {
      setFadeOut(true);
    }, 3000)  

    // Atraso de 3 segundos antes de mudar a div e esconder o spinner
    setTimeout(() => {
      setStarted(true); // Atualiza o estado para exibir o conteúdo principal após o atraso
      setLoading(false); // Esconde o spinner de loading
    }, 3200);
  };

  return (
    <>
      {!started ? (
        <div className={`pre-content ${fadeOut ? 'fade-out' : ''}`}>
          <p className='special2'>Para a Dona do meu <span className='stroke-text '>coração...</span></p>
          <p>Com Música</p>
          <p>A Vida Fica Mais Simples 🎶</p>
          <p>Aumente o Volume 🔊</p>
          <p className='teste'>e agora</p>
          <button onClick={handleStart}>
            <h3>Click Aqui 💕</h3>
          </button>
          {loading && <div className="spinner"></div>} {/* Spinner de loading */}
        </div>
      ) : (
        <div className='love-content fade-in'>
          <Carousel>
            <Carousel.Item interval={2500}>
              <img className="d-block carousel-image" src={Us_1} alt="First slide" />
              <Carousel.Caption>
                <p className='font-photo'>Te amo muito...</p>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item interval={2500}>
              <img className="d-block carousel-image" src={Us_2} alt="Second slide" />
              <Carousel.Caption>
                <p className='font-photo'>muito...</p>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item interval={2500}>
              <img className="d-block carousel-image" src={Us_3} alt="Third slide" />
              <Carousel.Caption>
                <p className='font-photo'>muito...</p>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item interval={2500}>
              <img className="d-block carousel-image" src={Us_5} alt="Third slide" />
              <Carousel.Caption>
                <p className='font-photo'>muito...</p>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item interval={2500}>
              <img className="d-block carousel-image" src={Us_6} alt="Third slide" />
              <Carousel.Caption>
                <p className='font-photo'>muito...</p>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item interval={2500}>
              <img className="d-block carousel-image" src={Us_7} alt="Third slide" />
              <Carousel.Caption>
                <p className='font-photo'>muito...</p>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item interval={2500}>
              <img className="d-block carousel-image" src={Us_8} alt="Third slide" />
              <Carousel.Caption>
                <p className='font-photo'>muito...</p>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item interval={2500}>
              <img className="d-block carousel-image" src={Us_9} alt="Third slide" />
              <Carousel.Caption>
                <p className='font-photo'>muito...</p>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item interval={10000}>
              <img className="d-block carousel-image" src={Us_4} alt="Fourth slide" />
              <Carousel.Caption>
                <p className='font-photo'>muito mesmo.</p>
              </Carousel.Caption>
            </Carousel.Item>
          </Carousel>
          <div className='description-content'>
            <h1>Feliz 2 Anos!!</h1>
            <hr />
            <p>Oi Gatinhaaaa 🥰</p>
            <p>Obrigado por fazer parte da minha vida, e vamos construir tudo que sonhamos e cada dia estamos mais proximos de viver isso</p>
            <p className='special'>Te Amo Infinito ❤️</p>
            <img className='boat' src={Boat} alt="Boat" />
          </div>
        </div>
      )}
    </>
  );
}

export default App;
